import { z } from 'zod';

import type { JamForm } from '../types/jamForm';
import { zJamFormStepApplication } from './useJamFormStepApplication';
import { zJamFormStepContent } from './useJamFormStepContent';
import { zJamFormStepData } from './useJamFormStepData';

export const useJamStepsValidation = () => {
    const isValid = (data: JamForm, validationRules: z.ZodSchema[]) => {
        let errorCount: number = 0;

        validationRules.forEach((rule) => {
            const e = rule.safeParse(data);

            if (!e.error) {
                return;
            }

            errorCount += e.error.issues.length;
        });

        return errorCount === 0;
    };

    // Step by step validation
    const validate = (data: JamForm) => {
        const invalidSteps: string[] = [];

        if (!isValid(data, [zJamFormStepData])) {
            invalidSteps.push('data');
        }
        if (!isValid(data, [zJamFormStepContent])) {
            invalidSteps.push('content');
        }
        if (!isValid(data, [zJamFormStepApplication])) {
            invalidSteps.push('application');
        }

        return invalidSteps;
    };

    return { validate };
};
