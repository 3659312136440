import type { JamServiceResponse } from '../composables/useJamService';
import { useJamStepsValidation } from '../composables/useJamStepsValidation';
import type { JamForm } from '../types/jamForm';

export default defineNuxtRouteMiddleware(async (to) => {
    const { isInternalUser } = useUser();

    const jobAdId = to.params.id;
    const companyId = to.params.companyId;

    const { data: ad } = await useFetch<JamServiceResponse<JamForm>>(
        isInternalUser.value
            ? `/api/manage-job-ads/internal/job-ads/${jobAdId}`
            : `/api/manage-job-ads/external/job-ads/${jobAdId}`,
    );

    if (!ad.value?.data) {
        return;
    }

    const buildRedirectUrl = (path: string) => {
        return isInternalUser.value
            ? `/p/internal/companies/${companyId}/jobs/${jobAdId}/${path}`
            : `/p/jobs/${jobAdId}/${path}`;
    };

    // If job ad is active, inactive or scheduled and target step is /publish, redirect to /summary
    if (
        ['ACTIVE', 'INACTIVE', 'SCHEDULED'].includes(
            <string>ad.value?.data?.meta?.status,
        ) &&
        to.path === buildRedirectUrl('publish')
    ) {
        return buildRedirectUrl('summary');
    }

    // Get invalid steps
    const { validate } = useJamStepsValidation();
    const invalidSteps = validate(ad.value?.data);

    const { stepsOrder } = useJamSteps();

    // Sort invalidSteps based on step order
    const sortedInvalidSteps = invalidSteps.sort(
        (a, b) => stepsOrder.indexOf(a) - stepsOrder.indexOf(b),
    );

    // Get target step
    const targetStep = to.path.split('/').pop();

    // If invalid steps are found before target step, redirect to the first invalid step
    const invalidStepsBeforeTargetStep = () => {
        return sortedInvalidSteps.some(
            (step) => stepsOrder.indexOf(step) < stepsOrder.indexOf(targetStep),
        );
    };

    if (invalidStepsBeforeTargetStep()) {
        return buildRedirectUrl(sortedInvalidSteps[0]);
    }
});
